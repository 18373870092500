<template>
  <div>
    <!-- header -->
    <WaBtn />
    <MainHeader />

    <!-- main banner -->
    <MainBanner />

    <!-- Our Brand -->
    <div class="py-5">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-pmb-purple">
          Our Brands
        </h1>
        <div class="w-10 h-1 mx-auto bg-pmb-purple"></div>
        <div class="pt-5">
          <div class=" md:flex md:flex-wrap md:justify-center xl:max-w-5xl xl:mx-auto">
            <img src="/images/brand-3.jpg" alt="" class="w-2/3 mx-auto md:px-2 md:w-1/2 lg:w-1/4" />
            <img src="/images/brand-1.jpg" alt="" class="w-2/3 mx-auto md:px-2 md:w-1/2 lg:w-1/4" />
            <img src="/images/brand-2.jpg" alt="" class="w-2/3 mx-auto md:px-2 md:w-1/2 lg:w-1/4" />
            <img src="/images/brand-4.jpg" alt="" class="w-2/3 mx-auto md:px-2 md:w-1/2 lg:w-1/4" />
          </div>
        </div>
      </div>
    </div>

    <!-- Products -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-pmb-purple">
          Our Products
        </h1>
        <div class="w-10 h-1 mx-auto bg-pmb-purple"></div>

        <!-- Tabs -->
        <div id="tabs" class="mt-5 md:flex">
          <div class="md:w-1/2">
            <p class="pb-5 text-2xl font-semibold text-center text-pmb-purple">
              Machines
            </p>
            <div class="flex justify-center md:justify-evenly">
              <div class="p-1 text-center">
                <div v-bind:class="[tab === 'tab-2' ? 'active' : '']" @click="tab = 'tab-2'"
                  class="mx-auto transition duration-300 ease-linear rounded-lg cursor-pointer w-36 hover:bg-pmb-purple bg-pmb-gray">
                  <img src="/images/icon-2.png" alt="Scissor Lift" class="p-3" />
                </div>
                <p class="mt-2 font-semibold">Electric Scissor Lifts</p>
              </div>
              <div class="p-1 text-center">
                <div v-bind:class="[tab === 'tab-3' ? 'active' : '']" @click="tab = 'tab-3'"
                  class="mx-auto transition duration-300 ease-linear rounded-lg cursor-pointer w-36 hover:bg-pmb-purple bg-pmb-gray">
                  <img src="/images/icon-3.png" alt="Boom Lift" class="p-3" />
                </div>

                <p class="mt-2 font-semibold">Electric/ Diesel Boomlift</p>
              </div>
            </div>
          </div>

          <div class="pt-5 md:w-1/2 md:pt-0">
            <p class="pb-5 text-2xl font-semibold text-center text-pmb-purple">
              Scaffold
            </p>
            <div class="flex justify-center md:justify-evenly">
              <div class="p-1 text-center">
                <div v-bind:class="[tab === 'tab-1' ? 'active' : '']" @click="tab = 'tab-1'"
                  class="mx-auto transition duration-300 ease-linear rounded-lg cursor-pointer w-36 bg-pmb-gray hover:bg-pmb-purple">
                  <img src="/images/icon-1.png" alt="Scaffolding Tower" class="p-3" />
                </div>
                <p class="mt-2 font-semibold">BS EN 1004</p>
              </div>
              <div class="p-1 text-center">
                <div v-bind:class="[tab === 'tab-4' ? 'active' : '']" @click="tab = 'tab-4'"
                  class="mx-auto transition duration-300 ease-linear rounded-lg cursor-pointer w-36 bg-pmb-gray hover:bg-pmb-purple">
                  <img src="/images/icon-4.png" alt="Scaffolding Tower" class="p-3" />
                </div>
                <p class="mt-2 font-semibold">BS EN 12811/MS 1462</p>
              </div>
            </div>
          </div>
        </div>

        <!-- tab content -->
        <div class="pt-8">
          <div v-show="tab == 'tab-1'" class="">
            <!-- instruction manual -->
            <a href="/images/pdf/instruction_manual.pdf" target="_blank" id="cta-manual">
              <div class="pb-4 md:w-3/4 md:mx-auto">
                <img src="/images/manual.jpg" alt="Instruction Manual" class="mx-auto rounded-md" />
              </div>
            </a>
            <div class="flex flex-wrap md:justify-center">
              <div v-for="(item, i) in scaffold" :key="i" class="w-1/2 p-1 md:w-1/4">
                <img :src="item.image" :alt="item.alt" class="rounded-t-md" />
                <div
                  class="p-3 text-gray-700 transition duration-300 ease-linear bg-gray-100 rounded-b-md hover:text-white hover:bg-pmb-purple">
                  <h1 class="h-24 py-4 text-sm font-medium leading-tight text-center lg:h-16">
                    {{ item.alt }}
                  </h1>
                  <a :href="item.LinkPDF" target="_blank">
                    <div class="w-32 mx-auto bg-gray-700 rounded-md">
                      <h1 class="py-1 text-sm text-center text-white">
                        View Specs
                      </h1>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div v-show="tab == 'tab-2'" class="">
            <div class="flex flex-wrap justify-center">
              <div v-for="(item, i) in scissor_lift" :key="i" class="p-1 md:w-1/3 lg:w-1/4">
                <img :src="item.image" :alt="item.alt" class="mx-auto" />
              </div>
            </div>
            <div class="mt-8">
              <p
                class="py-2 mx-auto text-lg font-medium text-center text-white transition duration-200 transform md:w-72 hover:scale-110 bg-pmb-red">
                <a href="/images/pdf/electric_scissor-2.pdf">View Our Product Catalog</a>
              </p>
            </div>
          </div>

          <div v-show="tab == 'tab-3'" class="">
            <div class="flex flex-wrap justify-center">
              <div v-for="(item, i) in boom" :key="i" class="p-1 md:w-1/3 lg:w-1/4">
                <img :src="item.image" :alt="item.alt" class="mx-auto" />
              </div>
            </div>
            <div class="mt-8">
              <p
                class="py-2 mx-auto text-lg font-medium text-center text-white transition duration-200 transform md:w-72 hover:scale-110 bg-pmb-red">
                <a href="/images/pdf/telescopic_boom-2.pdf">View Our Product Catalog</a>
              </p>
            </div>
          </div>

          <div v-show="tab == 'tab-4'" class="">
            <div class="flex flex-wrap md:justify-center">
              <div v-for="(item, i) in scaffold_2" :key="i" class="w-1/2 p-1 md:w-1/4">
                <img :src="item.image" :alt="item.alt" class="rounded-t-md" />
                <div
                  class="p-3 text-gray-700 transition duration-300 ease-linear bg-gray-100 rounded-b-md hover:text-white hover:bg-pmb-purple">
                  <h1 class="h-24 py-4 text-sm font-medium leading-tight text-center lg:h-16">
                    {{ item.alt }}
                  </h1>
                  <a :href="item.LinkPDF" target="_blank">
                    <div class="w-32 mx-auto bg-gray-700 rounded-md">
                      <h1 class="py-1 text-sm text-center text-white">
                        View Specs
                      </h1>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Is what we do -->
    <div class="bg-black">
      <div class="contianer">
        <div>
          <img src="/images/bg-do.jpg" alt="Is What We Do" class="mx-auto" />
        </div>
      </div>
    </div>

    <!-- Our Project -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-pmb-purple">
          Our Projects
        </h1>
        <div class="w-10 h-1 mx-auto bg-pmb-purple"></div>
        <!-- gallery -->
        <Gallery />
      </div>
    </div>

    <!-- Certificate -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-certificate.jpg')">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-white">
          Our Certificates
        </h1>
        <div class="w-10 h-1 mx-auto bg-pmb-purple"></div>
        <div class="pt-5 md:max-w-2xl md:mx-auto">
          <p class="text-sm font-light leading-tight text-center text-white">
            We are the 1st Aluminium Mobile Scaffold Tower manufacturer in
            Malaysia that offers a wide range of self-propelled aerial work
            platforms. Our Aluminium Mobile Scaffold Span Tower comes with full
            size aluminium toe board and castors wheels which are made by
            polyurethane material.
          </p>
        </div>
        <div class="pt-5">
          <div class="flex flex-wrap justify-center lg:mx-auto lg:max-w-3xl">
            <img src="/images/certificate-1.jpg" alt="" class="w-1/2 p-1 md:w-1/3" />
            <img src="/images/certificate-2.jpg" alt="" class="w-1/2 p-1 md:w-1/3" />
            <img src="/images/certificate-3.jpg" alt="" class="w-1/2 p-1 md:w-1/3" />
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-pmb-purple">
          About Us
        </h1>
        <div class="w-10 h-1 mx-auto bg-pmb-purple"></div>
        <div class="pt-6 text-sm text-center text-gray-700 lg:max-w-4xl lg:mx-auto">
          <p class="pb-3">
            <b>PMB Quick Access Sdn Bhd is one of the subsidiaries of PMB
              Technology Bhd. PMB Technology Group is listed in the 2nd board of
              Bursa Malaysia</b>
            which involved in the production and provision of downstream
            aluminium products, principally ranging from the designing,
            fabricating and installation of aluminium façade systems, to the
            manufacturing and sales of aluminium access products.
          </p>
          <p>
            We are the
            <b>1st Aluminium Mobile Scaffold Tower manufacturer in Malaysia that
              offers a wide range of self-propelled aerial work platforms.</b>
            Our Aluminium Mobile Scaffold Span Tower comes with full size
            aluminium toe board and castors wheels which are made by
            polyurethane material. And it is successfully achieved to
            international safety standard, both BSEN 1004-2004 and BSEN 12811 or
            Malaysia Standard MS 1462. We are also part of the associated member
            of Prefabricated Access Suppliers' and Manufacturers' Association
            (PASMA).
          </p>
        </div>
      </div>
    </div>
    <div class="py-12">
      <div class="container">
        <div class="relative p-5 overflow-hidden bg-right bg-no-repeat bg-cover shadow-xl rounded-2xl"
          style="background-image: url('/images/bg-everlast.jpg');">
          <div class="absolute top-0 left-0 w-full h-full bg-white md:bg-transparent md:bg-gradient-to-r md:bg-opacity-100 md:from-white md:to-transparent bg-opacity-80"></div>
          <div class="relative z-10 py-4 md:w-2/3 lg:pl-4">
            <img src="/images/everlast-logo.png" alt="" class="md:w-2/3 xl:w-[350px]">
            <div class="pt-3 text-gray-900">
              <p class="pb-3 font-semibold leading-tight md:leading-tight md:text-lg">Expand Your Work at Height Solutions with Everlast Ladders!
              </p>
              <p class="text-2xl font-bold leading-tight md:text-3xl lg:text-5xl">Leading Aluminium Ladder Manufacturer In Malaysia</p>
              <div class="mt-6">
                <p class="pb-3 font-bold lg:text-lg">Find Out More</p>
                <a href="https://www.everlas.com/">
                  <p class="inline-block px-4 py-2 font-semibold text-white transition-all duration-200 ease-linear bg-red-600 hover:scale-105 rounded-2xl">www.everlas.com</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- enquiry form -->
    <div id="element" class="py-10 bg-pmb-purple">
      <div class="container">
        <div class="md:max-w-xl lg:max-w-3xl md:mx-auto">
          <div class="pb-4 leading-tight">
            <h1 class="pb-3 text-2xl font-bold text-center text-white">
              Send an Enquiry
            </h1>
            <p class="text-sm text-center text-white">
              Fill out the form below and we'll get back to you as soon as
              possible
            </p>
          </div>
          <div id="enquire">
            <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true"
              style="min-height: 530px; height: inherit; overflow: auto" width="100%" id="contactform123"
              name="contactform123" marginwidth="0" marginheight="0" frameborder="0"
              src="https://feedback.activamedia.com.sg/my-contact-form-5242581.html">
              <p>
                Your browser does not support iframes. The contact form cannot
                be displayed. Please use another contact method (phone, fax etc)
              </p>
            </iframe><!-- feedback.activamedia.com.sg script ends here -->
          </div>
        </div>
      </div>
    </div>
    <img src="/images/bg-header.jpg" alt="" class="w-full" />
    <!-- footer -->
    <div class="py-10">
      <div class="container">
        <div class="md:flex md:justify-center md:items-center">
          <div class="pb-2 md:w-1/3">
            <img src="/images/main-logo.png" alt="" class="mx-auto" />
            <div class="pt-3 mx-auto w-52">
              <iframe frameborder="0" scrolling="no" allowTransparency="true" width="100%" height="91"
                src="https://cdn.yoshki.com/badge-pasma.html"
                style=" border: 0px; margin: 0px;padding: 0px;background-color: transparent;"></iframe>
            </div>
          </div>
          <div class="pb-2 text-center md:text-left md:w-1/3 md:px-1">
            <h1 class="text-xl font-bold leading-tight text-pmb-purple md:text-lg">
              PMB Quick Access <br class="hidden md:block" />
              Sdn Bhd
            </h1>
            <h1 class="py-4 text-sm font-normal text-gray-600">
              Lot 1804, Jalan Kpb 3, Kawasan Perindustrian Balakong, 43300 Seri
              Kembangan, Selangor
            </h1>
            <h1 class="text-base font-medium text-gray-600">
              Call Us :
              <a href="tel:+60386883911" class="transition duration-200 ease-in-out hover:text-red-600">03 8688 3911</a>
            </h1>
            <h1 class="text-base font-medium text-gray-600">
              Fax Number : +603 8688 3908
            </h1>
            <!-- <a href="https://www.everlas.com/">
              <div class="w-1/2 mx-auto mt-5 mb-5 text-center transition duration-200 rounded-lg hover:bg-gray-700 bg-pmb-purple md:mx-0 md:mb-0 ">
                <p class="p-1 px-3 text-sm font-semibold text-white lg:text-xl md:text-base">www.everlas.com</p>
              </div>
            </a> -->
          </div>
          <div class="md:w-1/3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.2369166649055!2d101.734318914757!3d3.0309824977926834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdcacc61760ffd%3A0x5f1c65a6b85bb966!2s1804%2C%20Jalan%20Kpb%203%2C%20Kawasan%20Perindustrian%20Balakong%2C%2043300%20Seri%20Kembangan%2C%20Selangor!5e0!3m2!1sen!2smy!4v1650607253600!5m2!1sen!2smy"
              width="100%" height="200" style="border: 0" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="py-3 bg-pmb-gray">
        <p class="text-xs text-center text-white">
          Website maintained by Activa Media. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Gallery from "@/components/Gallery.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";
import WaBtn from "@/components/WaBtn.vue"

export default {
  components: {
    Gallery,
    MainHeader,
    MainBanner,
    WaBtn
  },
  data() {
    return {
      tab: "tab-2",
      scaffold: [
        {
          image: "/images/product_1.jpg",
          alt: "Double Width Qaccess Model With Easy Access Stairway",
          LinkPDF:
            "https://storage.googleapis.com/activamedia/pmb/Product_1.pdf",
        },
        {
          image: "/images/product_2.jpg",
          alt: "Single Width Qaccess Model",
          LinkPDF:
            "https://storage.googleapis.com/activamedia/pmb/Product_2.pdf",
        },
        {
          image: "/images/product_3.jpg",
          alt: "Minisnap",
          LinkPDF:
            "https://storage.googleapis.com/activamedia/pmb/Product_3.pdf",
        },
        {
          image: "/images/product_4.jpg",
          alt: "Double Width Everlas Model With Straight Ladder",
          LinkPDF:
            "https://storage.googleapis.com/activamedia/pmb/Product_4.pdf",
        },
        {
          image: "/images/product_5.jpg",
          alt: "Single Width Everlas Model With Straight Ladder",
          LinkPDF:
            "https://storage.googleapis.com/activamedia/pmb/Product_5.pdf",
        },
        {
          image: "/images/product_6.jpg",
          alt: "Double Width Everlas Model With Easy Access Stairway",
          LinkPDF:
            "https://storage.googleapis.com/activamedia/pmb/Product_6.pdf",
        },
        // {
        //   image: "/images/product_7.jpg",
        //   alt: "Qaccess Aluminium Modular Scaffold System",
        //   LinkPDF:
        //     "https://storage.googleapis.com/activamedia/pmb/Product_7.pdf",
        // },
        // {
        //   image: "/images/product_8.jpg",
        //   alt: "Aluminium Scaffold Tower",
        //   LinkPDF: "/images/pdf/Product_8.pdf",
        // },
      ],
      scaffold_2: [
        {
          image: "/images/product_7.jpg",
          alt: "Qaccess Aluminium Modular Scaffold System",
          LinkPDF:
            "https://storage.googleapis.com/activamedia/pmb/Product_7.pdf",
        },
        {
          image: "/images/product_8.jpg",
          alt: "Aluminium Scaffold Tower",
          LinkPDF: "/images/pdf/Product_8_1.pdf",
        },
      ],
      scissor_lift: [
        { image: "/images/lift-1.jpg", alt: "Scissor Lift" },
        { image: "/images/lift-2.jpg", alt: "Scissor Lift" },
        { image: "/images/lift-3.jpg", alt: "Scissor Lift" },
        { image: "/images/lift-4.jpg", alt: "Scissor Lift" },
        { image: "/images/lift-5.jpg", alt: "Scissor Lift" },
        { image: "/images/lift-6.jpg", alt: "Scissor Lift" },
        { image: "/images/lift-7.jpg", alt: "Scissor Lift" },
        { image: "/images/lift-8.jpg", alt: "Scissor Lift" },
      ],
      boom: [
        { image: "/images/booms-1.jpg", alt: "Boom Lift" },
        { image: "/images/booms-2.jpg", alt: "Boom Lift" },
        { image: "/images/booms-3.jpg", alt: "Boom Lift" },
        { image: "/images/booms-4.jpg", alt: "Boom Lift" },
        { image: "/images/booms-5.jpg", alt: "Boom Lift" },
        { image: "/images/booms-6.jpg", alt: "Boom Lift" },
        { image: "/images/booms-7.jpg", alt: "Boom Lift" },
        { image: "/images/booms-8.jpg", alt: "Boom Lift" },
      ],
    };
  },
};
</script>
<style>
#tabs .active {
  background-color: #8653a2;
  color: #fff;
}

@media (min-width: 768px) {
  #enquire iframe {
    min-height: 525px !important;
  }
}
</style>
